import { Component, OnInit, OnDestroy, ComponentRef, ViewChild } from "@angular/core";
import { Router, ActivationEnd } from "@angular/router";
import { BehaviorSubject, Subscription, interval } from "rxjs";
import { filter } from "rxjs/operators";

import { Constants } from "src/app/constants/constants";
import { ModalService } from "src/app/components/shared/modals/modal.service";

import { EventsManagementService } from "../events-management.service";
import { AlertingProfile } from "../events-management";
//
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "src/app/services/title.service";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import {
    IconColumnComponent,
    IconTypes
} from "src/app/components/shared/table-list/tables-components/icon-column/icon-column.component";
import { KeyMap } from "src/app/models/shared";
import { TourService } from "ngx-ui-tour-md-menu";
import { MatTooltip } from "@angular/material/tooltip";
import { TourSteps } from "src/app/constants/tour-steps";

@Component({
    selector: "app-alerting-profiles-list",
    templateUrl: "./alerting-profiles-list.component.html"
})
export class AlertingProfilesListComponent implements OnInit, OnDestroy {
    refreshing = false;
    alertProfileID: number;
    selectedRows: Array<AlertingProfile> = [];

    urls = Constants.urls;
    alertProfiles$ = new BehaviorSubject<AlertingProfile[]>([]);

    private alertProfiles: AlertingProfile[];
    private routeSubscription: Subscription;
    private alertProfilesSubscription: Subscription;
    private alertProfilesRefreshSubscription: Subscription;
    private tourSubscription: Subscription;

    @ViewChild("tooltip", { static: false }) tourTooltip: MatTooltip;

    tableColumnsSchema: TableSchema[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            visible: true,
            sticky: 1,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<AlertingProfile>(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: (row: AlertingProfile): string => row.name,
            textValue: (row: AlertingProfile): string => row.name
        },
        {
            header: this.translate.instant("MODIFIED_RULES"),
            columnDef: "modified_rules",
            width: 140,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<AlertingProfile>(
                row => row.alertingRules.length.toString(),
                row => row.alertingRules.length.toString(),
                () => true
            ),
            sortBy: (row: AlertingProfile) => row.alertingRules.length,
            textValue: (row: AlertingProfile): string => row.alertingRules.length.toString()
        },
        {
            header: this.translate.instant("DEFAULT"),
            columnDef: "default",
            width: 100,
            visible: true,
            component: IconColumnComponent,
            assignComponentsInputs: (componentRef: ComponentRef<IconColumnComponent>, row: KeyMap<AlertingProfile>) => {
                const componentInstance = componentRef.instance;
                componentInstance.iconType = IconTypes.CHECK;
                componentInstance.showIcon = row.default;
            },
            sortBy: (row: AlertingProfile) => (row.default ? 1 : 0),
            textValue: (row: AlertingProfile) => (row.default ? 1 : 0)
        }
    ];

    constructor(
        private router: Router,
        private ems: EventsManagementService,
        private modalService: ModalService,
        public mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService,
        public tourService: TourService
    ) {
        //
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0))
            .subscribe((event: ActivationEnd) => {
                if (event.snapshot.params && event.snapshot.params.id) {
                    this.alertProfileID = parseInt(event.snapshot.params.id, 10);
                } else {
                    // Set Title
                    this.titleService.setTitle("ALERT_PROFILES", "");

                    this.alertProfileID = null;
                    this.selectedRows = [];
                }
            });
    }

    updateSelectedRows() {
        if (this.alertProfileID) {
            const existing = this.alertProfiles.find(p => p.id === this.alertProfileID);
            this.selectedRows = existing ? [existing] : [];
        }
    }

    private tourSteps = TourSteps.alertingProfilesList;

    ngOnInit() {
        // local storage
        document.getElementById("left-container").style.flexBasis = localStorage.getItem("list-panel-width");

        this.alertProfilesSubscription = this.ems.alertingProfiles.subscribe(alertProfiles => {
            this.alertProfiles = alertProfiles;
            if (this.alertProfiles) {
                this.setDefaultProfileRoute();
                this.alertProfiles$.next([...alertProfiles]);
                this.updateSelectedRows();
            }
        });

        // Start Auto Refresh
        this.startAlertProfilesRefresh();

        setTimeout(() => {
            this.updateSelectedRows();
            // Show tooltip on first page visit
            if (!localStorage.getItem("eventsProfilesVisited")) {
                this.tourTooltip.toggle();
                localStorage.setItem("eventsProfilesVisited", "true");
            }
        });

        this.tourService.initialize(this.tourSteps);
        this.tourSubscription = this.tourService.stepShow$.subscribe(step => {
            if (step.step.stepId === "3") {
                this.updateSelectedRows();
            }
        });
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
        this.alertProfilesSubscription.unsubscribe();
        this.tourSubscription.unsubscribe();
        this.stopAlertProfilesRefresh();
    }

    setDefaultProfileRoute() {
        const def = this.alertProfiles.find(p => p.default);
        // TODO update to use string and find
        this.tourSteps[2].route = Constants.urls.configuration.eventsManagement + "/" + def.id;
    }

    selectRow = (alertingProfile: AlertingProfile) => {
        this.alertProfileID = alertingProfile.id;
        this.router.navigate([Constants.urls.configuration.eventsManagement, alertingProfile.id]);
    };

    async refresh() {
        this.refreshing = true;
        const alertProfiles = this.ems.refreshAlertingProfiles().toPromise();
        await Promise.all([alertProfiles]);
        this.refreshing = false;
    }

    private async multiAction(action: string, func: (alertProfile: AlertingProfile) => Promise<unknown>) {
        const result = await this.modalService.confirmMultiple(action, "ALERT_PROFILE", this.selectedRows, func);
        if (result.actionTaken) {
            this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " multiple alert profiles");
            if (action === "DELETE") this.selectedRows = [];
        }
    }

    multiDelete() {
        this.multiAction("DELETE", async (alertProfile: AlertingProfile) =>
            this.ems.deleteAlertingProfile(alertProfile)
        );
    }

    private startAlertProfilesRefresh() {
        this.alertProfilesRefreshSubscription = interval(60000).subscribe(() => {
            this.refresh();
        });
    }

    private stopAlertProfilesRefresh() {
        this.alertProfilesRefreshSubscription.unsubscribe();
    }

    get alert_profiles$() {
        return this.alertProfiles$.asObservable();
    }
}
